@tailwind base;
@tailwind components;
@tailwind utilities;


.background-image-dashboard {
  background-image: url("https://images.unsplash.com/photo-1613469425754-bf71d7280f65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.2);

}
#message{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

}
 .no-stick-no-z{
  position: relative!important;
  z-index: 0!important;
 }