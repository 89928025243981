@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar{
    position: fixed;

    top: 130px;
    right: 30px;
}

/* navigation for the website */
